<template>
  <Box customClass="member-preview">
    <div class="is-flex is-align-items-center">
      <div class="member-preview__avatar mr-3">
        <div class="member-preview__avatar_pic">
          <figure class="image is-64x64">
            <img v-if="!member.image_name" src="@/assets/img/dummy-avatar.jpg" alt="" class="is-rounded"/>
            <img v-if="member.image_name" :src="`${cmsUrl}images/profile/${user.image_name}`" alt="" class="is-rounded"/>
          </figure>
        </div>
      </div>
      <div class="member-preview__content px-3 is-flex is-align-items-center">
        <div class="member-preview__content-name mr-5 has-text-weight-bold is-size-4">{{ member.name }}</div>
        <div class="member-preview__content-email">{{ member.email }}</div>
      </div>
      <div class="member-preview__action">
        <slot></slot>
      </div>
    </div>
  </Box>
</template>

<script>
import {reactive, ref, toRefs} from 'vue'
import Box from '@/components/ds/Box.vue'

export default {
  components: {
    Box,
  },
  props: {
    user: {
      Type: Object,
      default: {}
    }
  },
  setup(props) {
    const cmsUrl = process.env.VUE_APP_CMS_URL
    const member = ref(props.user)
    const state = reactive({
      noimg: '@/assets/img/dummy-avatar.jpg'
    })

    return {
      ...toRefs(state),
      member,
      cmsUrl
    }
  }
}
</script>

<style lang="scss">
.member-preview {
 &__action {
   display: flex;
   flex-direction: row;
   margin-left: auto;

   a {

   }
 }
}
</style>
